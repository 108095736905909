import React, { Suspense } from 'react';
import { HashRouter } from 'react-router-dom';
import configureStore from 'redux-mock-store';
import properties from '../state/properties/initial-state';
import '../i18n';

const mockStore = configureStore();
const store = mockStore({ properties });

const withTranslation = (Component, props) => (
  <Suspense fallback={null}>
    <Component {...props} />
  </Suspense>
);

const withRouter = component => (
  <HashRouter basename={'/'}>{component}</HashRouter>
);

export { store, withTranslation, withRouter };
