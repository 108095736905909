import loadable from '@loadable/component';
import { ROUTES } from './constants/navigation';
import App from './App';
import Layout from './components/Layout';

const routes = [
  {
    component: App,
    routes: [
      {
        path: ROUTES.QUERY_MANAGER.PATH,
        component: Layout,
        rules: { isPrivate: true },
        routes: [
          {
            path: ROUTES.QUERY_MANAGER_NEW.PATH,
            component: loadable(() =>
              import(
                /* webpackChunkName: "query-manager-import" */ './pages/QueryManager/Import'
              )
            )
          }
        ]
      },
      {
        path: ROUTES.ROOT.PATH,
        component: Layout,
        rules: { isPrivate: false },
        routes: [
          {
            path: ROUTES.ROOT.PATH,
            component: loadable(() =>
              import(/* webpackChunkName: "sign-in" */ './pages/SignIn')
            )
          },
          {
            path: ROUTES.SIGN_IN.PATH,
            component: loadable(() =>
              import(/* webpackChunkName: "sign-in" */ './pages/SignIn')
            )
          }
        ]
      }
    ]
  }
];

export default routes;
