import {
  TABLEAU_IMPORT_PROGRESS,
  TABLEAU_IMPORT_SUCCESS,
  TABLEAU_IMPORT_FAIL
} from './action-types';
import { tableauDHIS2AnalyticsImportStart as service } from '../../services/tableau';

const tableauImportStart = payload => dispatch => service(payload, dispatch);

const tableauImportSuccess = payload => ({
  type: TABLEAU_IMPORT_PROGRESS,
  payload
});

const tableauImportProgress = payload => ({
  type: TABLEAU_IMPORT_SUCCESS,
  payload
});

const tableauImportFail = payload => ({
  type: TABLEAU_IMPORT_FAIL,
  payload
});

export {
  tableauImportStart,
  tableauImportSuccess,
  tableauImportProgress,
  tableauImportFail
};
