import { default as api } from 'axios';
import {
  API_PATH_PROFILE,
  API_PATH_DATA_ELEMENTS,
  API_PATH_ORG_UNITS,
  API_PATH_ANALYTICS
} from '../constants/api';
import { queryUtils } from '../utils';

const configureAPI = (baseURL, auth) => {
  api.defaults.auth = auth;
  api.defaults.baseURL = baseURL;
};

const getProfile = (baseURL, auth) => {
  configureAPI(baseURL, auth);
  return api.get(API_PATH_PROFILE);
};

const getDataElementGroups = () => api.get(API_PATH_DATA_ELEMENTS);
const getOrgUnits = () => api.get(API_PATH_ORG_UNITS);

/**
 * Fetch data from DHIS2 using the provided query model
 *
 * @param queryModel The model from which the request is to be created
 * @return {Promise<AxiosResponse<T>>} The response from DHIS2
 */
const getDHIS2AnalyticsData = queryModel =>
  api.get(API_PATH_ANALYTICS + queryUtils.getDHIS2AnalyticsQuery(queryModel));

export {
  configureAPI,
  getProfile,
  getDataElementGroups,
  getOrgUnits,
  getDHIS2AnalyticsData
};
