import { signInStart as service } from '../../services/dhis2-api';
import {
  HANDLE_UNAUTHORIZED_ACCESS,
  SIGN_IN_FAIL,
  SIGN_IN_SUCCESS,
  SIGN_OUT
} from './action-types';
import { SET_PROPERTY } from '../properties/action-types';
import { push } from 'connected-react-router';
import { ROUTES } from '../../constants/navigation';

export const signInStart = payload => dispatch => service(payload, dispatch);

export const signInSuccess = (payload, propertiesPayload) => dispatch => {
  dispatch({ type: SIGN_IN_SUCCESS, payload });
  dispatch({ type: SET_PROPERTY, payload: propertiesPayload });
  dispatch(push(ROUTES.ROOT_LOGGED.PATH));
};

export const signInFail = payload => {
  return {
    type: SIGN_IN_FAIL,
    payload
  };
};

export const signOut = () => dispatch => {
  dispatch({ type: SIGN_OUT });
  dispatch(push(ROUTES.ROOT.PATH));
};

export const handleUnauthorizedAccess = () => dispatch => {
  dispatch({ type: HANDLE_UNAUTHORIZED_ACCESS });
  dispatch(push(ROUTES.SIGN_IN.PATH));
};
