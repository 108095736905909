import { DEFAULT_UUID, IMPORT_STATUS } from '../../constants';

const initialState = {
  dataImport: {
    [DEFAULT_UUID]: {
      status: IMPORT_STATUS.INITIAL,
      data: {},
      info: {
        queryUID: DEFAULT_UUID,
        startedAt: '1572019317705',
        completedAt: '1572019392628',
        progress: 100
      }
    }
  }
};

export default initialState;
