const getDataElementGroupsDimension = queryModel => {
  const dataElementGroups = queryModel.dataElementGroups
    .filter(deg => !deg.dataElements || deg.dataElements.length === 0)
    .map(deg => deg.id);
  return dataElementGroups
    .filter(deg => !!deg)
    .filter(deg => !queryModel.dirtyGroups[deg])
    .map(deg => `DE_GROUP-${deg}`)
    .filter(Boolean)
    .join(';');
};

const dataElementGroupsToDataElements = queryModel => {
  let dataElements = [];
  const dataElementGroups = queryModel.dataElementGroups
    .map(deg => deg)
    .filter(deg => queryModel.dirtyGroups[deg.id]);

  dataElementGroups.forEach(
    deg =>
      (dataElements = dataElements.concat(
        queryModel.dirtyGroups[deg.id].children
      ))
  );
  return dataElements;
};

const getDataElementsDimension = queryModel => {
  const dataElements = [];
  dataElements.push(...queryModel.dataElements.map(de => de.id));
  queryModel.dataElementGroups
    .filter(deg => !!deg.dataElements && deg.dataElements.length > 0)
    .map(deg => deg.dataElements)
    .forEach(deArr => dataElements.push(...deArr.map(de => de.id)));

  return dataElements
    .concat(dataElementGroupsToDataElements(queryModel))
    .filter(Boolean)
    .join(';');
};

const getDataElementsWithGroupsDimensions = queryModel => {
  const dataElementGroupsDimension = getDataElementGroupsDimension(queryModel);
  const dataElementsDimension = getDataElementsDimension(queryModel);

  const dx = [dataElementGroupsDimension, dataElementsDimension]
    .filter(Boolean)
    .join(';');
  return `dimension=dx:${dx}`;
};

const getOrgUnitsDimension = queryModel => {
  const dx = queryModel.organisationUnits
    .map(ou => ou)
    .filter(Boolean)
    .join(';');
  return `dimension=ou:${dx}`;
};

const getPeriodDimension = queryModel => {
  if (queryModel.periodType) {
    return `dimension=pe:${queryModel.periodType}`;
  } else {
    const dates = {
      startDate: queryModel.periodFrom,
      endDate: queryModel.periodTo
    };

    return Object.entries(dates)
      .filter(entry => !!entry[1]) // Ignore fields with empty values
      .map(entry => `${entry[0]}=${entry[1]}`)
      .join('&');
  }
};

const getDHIS2AnalyticsQuery = queryModel => {
  const params = [
    getDataElementsWithGroupsDimensions(queryModel),
    getPeriodDimension(queryModel),
    getOrgUnitsDimension(queryModel),
    'skipMeta=false',
    'ignoreLimit=true',
    'hideEmptyRows=true'
  ]
    .filter(Boolean)
    .join('&');

  return `?${params}`;
};

export { getDHIS2AnalyticsQuery };
