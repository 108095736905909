import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { localizationUtils } from '../utils';
import { setProperty } from '../state/properties/actions';

import App from './App';

import resources from './locales';

const { withLocalization } = localizationUtils;

const mapStateToProps = state => ({
  theme: state.properties.theme
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setProperty
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalization(App, resources, 'App'));
