export const GET_DHIS2_ALL_DATA_REQUEST = 'GET_DHIS2_ALL_DATA_REQUEST';
export const GET_DHIS2_ALL_DATA_SUCCESS = 'GET_DHIS2_ALL_DATA_SUCCESS';
export const GET_DHIS2_ALL_DATA_FAIL = 'GET_DHIS2_ALL_DATA_FAIL';
export const GET_DHIS2_DATA_ELEMENTS_REQUEST =
  'GET_DHIS2_DATA_ELEMENTS_REQUEST';
export const GET_DHIS2_DATA_ELEMENTS_SUCCESS =
  'GET_DHIS2_DATA_ELEMENTS_SUCCESS';
export const GET_DHIS2_DATA_ELEMENTS_FAIL = 'GET_DHIS2_DATA_ELEMENTS_FAIL';
export const GET_DHIS2_ORG_UNITS_REQUEST = 'GET_DHIS2_ORG_UNITS_REQUEST';
export const GET_DHIS2_ORG_UNITS_SUCCESS = 'GET_DHIS2_ORG_UNITS_SUCCESS';
export const GET_DHIS2_ORG_UNITS_FAIL = 'GET_DHIS2_ORG_UNITS_FAIL';
export const GET_DHIS2_ANALYTICS_REQUEST = 'GET_DHIS2_ANALYTICS_REQUEST';
export const GET_DHIS2_ANALYTICS_SUCCESS = 'GET_DHIS2_ANALYTICS_SUCCESS';
export const GET_DHIS2_ANALYTICS_FAIL = 'GET_DHIS2_ANALYTICS_FAIL';
export const CREATE_DHIS2_QUERY = 'CREATE_DHIS2_QUERY';
export const UPDATE_DHIS2_QUERY = 'UPDATE_DHIS2_QUERY';
export const DELETE_DHIS2_QUERY = 'DELETE_DHIS2_QUERY';
