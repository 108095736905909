const findNode = (currentNode, id) => {
  const node = currentNode.find(treeItem => treeItem.id === id);
  if (node) {
    return node;
  }
  return currentNode.map(treeItem => findNode(treeItem.children, id))[0];
};

const flatToTree = data => {
  const tree = data
    .filter(item => item.level === 1)
    .map(item => ({ children: [], ...item }));

  data.forEach(item => {
    if (item.parent) {
      const node = tree.find(treeItem => treeItem.id === item.parent.id);
      if (node) {
        node.children.push({ ...item, children: [...(item.children || [])] });
      } else {
        const deepNode = findNode(tree, item.parent.id);
        if (deepNode) {
          deepNode.children.push({
            ...item,
            children: [...(item.children || [])]
          });
        }
      }
    }
  });

  return tree;
};

export { flatToTree };
