import * as apiUtils from './api-utils';
import * as queryUtils from './query-generator-utils';
import * as localizationUtils from './localization-utils';
import * as testsUtils from './tests-utils';
import * as dataUtils from './data-utils';

const formatDate = date =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export {
  apiUtils,
  localizationUtils,
  queryUtils,
  testsUtils,
  dataUtils,
  formatDate
};
