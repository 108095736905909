export const ROUTES = {
  ROOT: { PATH: '/' },
  ROOT_LOGGED: { PATH: '/query-manager/new' },
  SIGN_IN: { PATH: '/sign-in' },
  CONTACT: { PATH: '/contact' },
  FAQ: { PATH: '/faq' },
  QUERY_MANAGER: { PATH: '/query-manager/' },
  QUERY_MANAGER_HISTORY: { PATH: '/query-manager/history' },
  QUERY_MANAGER_NEW: { PATH: '/query-manager/new' }
};
