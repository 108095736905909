import {
  GET_DHIS2_ALL_DATA_FAIL,
  GET_DHIS2_ALL_DATA_REQUEST,
  GET_DHIS2_ALL_DATA_SUCCESS
} from '../action-types';
import {
  API_PATH_DATA_ELEMENTS,
  API_PATH_ORG_UNITS
} from '../../../constants/api';
import {
  getDHIS2DataElementsFail,
  getDHIS2DataElementsSuccess
} from './dataElements';
import { getDHIS2OrgUnitsFail, getDHIS2OrgUnitsSuccess } from './orgUnits';
import { SET_PROPERTY } from '../../properties/action-types';
import { COMPONENT_STATUS } from '../../../constants';

const RESQUEST_PATHS = [API_PATH_DATA_ELEMENTS, API_PATH_ORG_UNITS];
const loader = {
  show: true,
  title: 'Import data from DHIS2',
  label: "Loading from DHIS2. Please, wait until it's complete...",
  status: COMPONENT_STATUS.INITIAL
};

const getDHIS2AllDataRequest = payload => (dispatch, getState) => {
  const {
    properties: {
      api: { baseURL, username, password }
    }
  } = getState();
  dispatch({
    type: GET_DHIS2_ALL_DATA_REQUEST,
    payload: {
      ...payload,
      API_CONFIG: {
        baseURL: baseURL,
        auth: username &&
          password && {
            username,
            password
          },
        path: RESQUEST_PATHS,
        onStart: getDHIS2AllDataStart,
        onSuccess: getDHIS2AllDataSuccess,
        onFail: getDHIS2AllDataFail,
        onDownloadProgress: progressEvent => dispatch => {
          dispatch({
            type: SET_PROPERTY,
            payload: {
              loader: {
                ...loader,
                status: COMPONENT_STATUS.LOADING,
                progress: progressEvent.loaded,
                isIndeterminate: Boolean(!progressEvent.total)
              }
            }
          });

          /*console.log(
          'downloads',
          progressEvent,
          progressEvent.total,
          progressEvent.loaded,
          Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        );*/
        }
      }
    }
  });
};

const getDHIS2AllDataStart = () => dispatch =>
  dispatch({
    type: SET_PROPERTY,
    payload: { loader }
  });

const getDHIS2AllDataSuccess = (dataElement, orgUnits) => dispatch => {
  dispatch(getDHIS2DataElementsSuccess(dataElement.data));
  dispatch(getDHIS2OrgUnitsSuccess(orgUnits.data));
  dispatch({
    type: GET_DHIS2_ALL_DATA_SUCCESS,
    payload: RESQUEST_PATHS
  });
  dispatch({
    type: SET_PROPERTY,
    payload: {
      loader: { ...loader, show: false, status: COMPONENT_STATUS.LOADED }
    }
  });
};

const getDHIS2AllDataFail = error => dispatch => {
  const payload = { paths: RESQUEST_PATHS, error };
  dispatch(getDHIS2DataElementsFail(payload));
  dispatch(getDHIS2OrgUnitsFail(payload));
  dispatch({ type: GET_DHIS2_ALL_DATA_FAIL, payload });
};

export { getDHIS2AllDataRequest, getDHIS2AllDataSuccess, getDHIS2AllDataFail };
