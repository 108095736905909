import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import styles from './styles';
import { View, Text } from '@baosystems/nebula';
// TODO: Move to icons component
import { ReactComponent as Logo } from '../../assets/svg/logo-bao-symbol.svg';

const Container = styled(View)(styles.container);
const CreditsText = styled(Text)(styles.creditsText);
const Nav = styled(View)(styles.nav);

const Footer = ({ creditsText, navigation }) => (
  <Container>
    <Logo css={styles.logo} />
    <CreditsText>{creditsText}</CreditsText>
    <Nav>
      {navigation &&
        navigation.map(item => (
          <a key={item.to} href={item.to} target={item.target}>
            <Text>{item.label}</Text>
          </a>
        ))}
    </Nav>
  </Container>
);

Footer.propTypes = {
  creditsText: PropTypes.string
};

export default Footer;
