import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import createRootReducer from './root-reducers';
import { createMiddleware as createGTMMiddleware } from 'redux-beacon';
import eventsMap from './event-definitions';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import apiMiddleware from '../utils/middleware/api';

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_ROOT_ROUTER || '/#/'
});
const gtmMiddleware = createGTMMiddleware(eventsMap, GoogleTagManager());
const middleWares = [
  thunk,
  routerMiddleware(history),
  apiMiddleware,
  logger,
  gtmMiddleware
];

export default function configureStore(preloadedState) {
  return createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleWares))
  );
}
