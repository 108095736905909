import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import styles from './styles';
import { Row, Col, Text } from '@baosystems/nebula';
// TODO: Move to icons component
import { ReactComponent as LogoTableau } from '../../assets/svg/logo-tableau-connector.svg';

const Container = styled(Row)(styles.container);
const LogoContainer = styled(Col)(styles.logoContainer);
const ProfileEmail = styled(Text)(styles.profile.email);
const SignOutLink = styled(Text)(styles.signOutLink);
const Nav = styled(Col)(styles.nav);

const Header = ({ profile = {}, headerNavigation, signOut }) => (
  <Container>
    <LogoContainer>
      <LogoTableau viewBox="0 0 210 24" width={152} />
    </LogoContainer>
    <Nav>
      <Row type="flex" justify="end" gutter={16}>
        <Col>
          <ProfileEmail>{profile.email}</ProfileEmail>
        </Col>
        <Col>
          <SignOutLink onClick={signOut}>
            {headerNavigation.signOut.label}
          </SignOutLink>
        </Col>
      </Row>
    </Nav>
  </Container>
);

Header.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string
  }),
  headerNavigation: PropTypes.object,
  signOut: PropTypes.func
};

export default Header;
