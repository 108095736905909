import {
  getProfile,
  getDataElementGroups,
  getOrgUnits
} from '../utils/api-utils';
import { signInSuccess, signInFail } from '../state/profile/actions';
import {
  getDHIS2DataElementsSuccess,
  getDHIS2DataElementsFail,
  getDHIS2OrgUnitsSuccess,
  getDHIS2OrgUnitsFail,
  getDHIS2AnalyticsRequest
} from '../state/dhis2/actions';
import { SIGN_IN_START } from '../state/profile/action-types';
import { GET_DHIS2_DATA_ELEMENTS_REQUEST } from '../state/dhis2/action-types';
import {
  STATUS_NOT_FOUND,
  STATUS_TEXT_NOT_FOUND,
  STATUS_TEXT_UNAUTHORIZED,
  STATUS_UNAUTHORIZED
} from '../constants/api';

const signInStart = (data, dispatch) => {
  const { url, username, password } = data;
  dispatch({ type: SIGN_IN_START });
  return getProfile(url, { username, password })
    .then(({ data: { name, email } }) =>
      dispatch(
        signInSuccess(
          { name, email },
          {
            api: {
              baseURL: url
            }
          }
        )
      )
    )
    .catch(error => {
      let statusText = STATUS_TEXT_NOT_FOUND;
      let statusCode = STATUS_NOT_FOUND;
      if (error.response && error.response.status === STATUS_UNAUTHORIZED) {
        statusText = STATUS_TEXT_UNAUTHORIZED;
        statusCode = STATUS_UNAUTHORIZED;
      }
      return dispatch(signInFail({ statusCode, statusText }));
    });
};

const getDataElementsStart = (data, dispatch) => {
  dispatch({ type: GET_DHIS2_DATA_ELEMENTS_REQUEST });
  return getDataElementGroups()
    .then(({ data }) => dispatch(getDHIS2DataElementsSuccess(data)))
    .catch(error => dispatch(getDHIS2DataElementsFail({ error })));
};

const getOrgUnitsStart = (data, dispatch) => {
  dispatch({ type: GET_DHIS2_DATA_ELEMENTS_REQUEST });
  return getOrgUnits()
    .then(({ data }) => dispatch(getDHIS2OrgUnitsSuccess(data)))
    .catch(error => dispatch(getDHIS2OrgUnitsFail({ error })));
};

const getAnalyticsStart = (data, dispatch) =>
  dispatch(getDHIS2AnalyticsRequest(data));

export {
  signInStart,
  getAnalyticsStart,
  getDataElementsStart,
  getOrgUnitsStart
};
