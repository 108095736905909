import { COMPONENT_STATUS } from '../../constants';

const initialState = {
  logged: false,
  status: COMPONENT_STATUS.INITIAL,
  data: {
    name: '',
    email: ''
  }
};

export const mockedInitialState = {
  logged: true,
  status: COMPONENT_STATUS.LOADED,
  data: {
    name: 'klayton',
    email: 'kfaria@baosystems.com'
  }
};

export default process.env.REACT_APP_USE_MOCK !== 'true'
  ? initialState
  : mockedInitialState;
