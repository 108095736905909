import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { localizationUtils } from './utils';
import { default as appLocales } from './App/locales';

i18n.use(initReactI18next).init({
  lng: 'en-US',
  interpolation: {
    escapeValue: false
  }
});

/** Global resources */
localizationUtils.setupResources('appLocales', appLocales);

export default i18n;
