import * as tableauWrapper from '../utils/tableau-wrapper';

const META_WHITELIST = ['dx', 'ou'];
const META_SUFFIX_ID = 'Name';
const META_SUFFIX_LABEL = ' name';

const tableauDHIS2AnalyticsImportStart = payload =>
  new Promise((resolve, reject) => {
    try {
      const schema = getDHIS2AnalyticsSchema(payload.headers);
      const data = getDHIS2AnalyticsData(payload);
      const tableauData = { schema, data };
      tableauWrapper.submit('BAO Connector', JSON.stringify(tableauData));
      resolve(tableauData);
    } catch (e) {
      reject(e);
    }
  });

const getDHIS2AnalyticsSchema = headers => {
  const valueTypeMap = {
    TEXT: tableauWrapper.dataTypes.string,
    NUMBER: tableauWrapper.dataTypes.float
  };

  let cols = [];
  cols = cols.concat(
    headers.map(entry => {
      // debugger;

      if (entry.meta && META_WHITELIST.some(item => item === entry.name)) {
        cols.push({
          id: entry.name + META_SUFFIX_ID,
          alias: entry.column + META_SUFFIX_LABEL,
          dataType: 'string'
        });
      }

      return {
        id: entry.name,
        alias: entry.column,
        dataType: valueTypeMap[entry.valueType]
      };
    })
  );

  return {
    alias: 'DHIS2 Analytics',
    id: 'analyticsTable',
    columns: cols
  };
};

const getDHIS2AnalyticsData = ({ rows, headers, metaData }) => {
  if (rows) {
    const columnIndices = headers.map(entry => entry.name);

    return rows.map(row =>
      row.reduce((prevValue, currVal, currIndex) => {
        const columnIndex = columnIndices[currIndex];
        const { name: metaDataName } =
          (metaData && metaData.items[currVal]) || {};
        const metadataRow = metaDataName
          ? { [columnIndex + META_SUFFIX_ID]: metaDataName }
          : null;
        return {
          ...prevValue,
          [columnIndex]: currVal,
          ...metadataRow
        };
      }, {})
    );
  } else {
    tableauWrapper.abortWithError('No results found');
  }
};

export { tableauDHIS2AnalyticsImportStart };
