import {
  TABLEAU_IMPORT_START,
  TABLEAU_IMPORT_PROGRESS,
  TABLEAU_IMPORT_SUCCESS,
  TABLEAU_IMPORT_FAIL
} from './action-types';
import initialState from './initial-state';
import { IMPORT_STATUS } from '../../constants';

export default (state = initialState, action = {}) => {
  const { info } = action.payload || {};

  switch (action.type) {
    case TABLEAU_IMPORT_START:
      return {
        ...state,
        dataImport: {
          ...state.dataImport,
          [info.queryUID]: action.payload,
          status: IMPORT_STATUS.IMPORTING
        }
      };
    case TABLEAU_IMPORT_PROGRESS:
      return {
        ...state,
        dataImport: {
          ...state.dataImport,
          [info.queryUID]: {
            ...state.dataImport[info.queryUID],
            ...action.payload,
            status: IMPORT_STATUS.IMPORTING
          }
        }
      };
    case TABLEAU_IMPORT_SUCCESS:
      delete state.queries[action.payload.uid];
      return {
        ...state,
        dataImport: {
          ...state.dataImport,
          [info.queryUID]: {
            ...state.dataImport[info.queryUID],
            ...action.payload,
            status: IMPORT_STATUS.IMPORTED
          }
        }
      };
    case TABLEAU_IMPORT_FAIL:
      delete state.queries[action.payload.uid];
      return {
        ...state,
        dataImport: {
          ...state.dataImport,
          [info.queryUID]: {
            ...state.dataImport[info.queryUID],
            ...action.payload,
            status: IMPORT_STATUS.ERROR
          }
        }
      };

    default:
      return state;
  }
};
