import {
  GET_DHIS2_DATA_ELEMENTS_FAIL,
  GET_DHIS2_DATA_ELEMENTS_REQUEST,
  GET_DHIS2_DATA_ELEMENTS_SUCCESS
} from '../action-types';
import { API_PATH_DATA_ELEMENTS } from '../../../constants/api';

const getDHIS2DataElementsRequest = payload => (dispatch, getState) => {
  const {
    properties: {
      api: { baseURL, username, password }
    }
  } = getState();

  dispatch({
    type: GET_DHIS2_DATA_ELEMENTS_REQUEST,
    payload: {
      ...payload,
      API_CONFIG: {
        baseURL: baseURL,
        auth: username &&
          password && {
            username,
            password
          },
        path: API_PATH_DATA_ELEMENTS,
        onSuccess: getDHIS2DataElementsSuccess,
        onFail: getDHIS2DataElementsFail
      }
    }
  });
};

const getDHIS2DataElementsSuccess = payload => ({
  type: GET_DHIS2_DATA_ELEMENTS_SUCCESS,
  payload
});

const getDHIS2DataElementsFail = payload => ({
  type: GET_DHIS2_DATA_ELEMENTS_FAIL,
  payload
});

export {
  getDHIS2DataElementsRequest,
  getDHIS2DataElementsSuccess,
  getDHIS2DataElementsFail
};
