import {
  GET_DHIS2_DATA_ELEMENTS_REQUEST,
  GET_DHIS2_DATA_ELEMENTS_SUCCESS,
  GET_DHIS2_DATA_ELEMENTS_FAIL,
  GET_DHIS2_ORG_UNITS_REQUEST,
  GET_DHIS2_ORG_UNITS_SUCCESS,
  GET_DHIS2_ORG_UNITS_FAIL,
  GET_DHIS2_ANALYTICS_REQUEST,
  GET_DHIS2_ANALYTICS_SUCCESS,
  GET_DHIS2_ANALYTICS_FAIL,
  CREATE_DHIS2_QUERY,
  UPDATE_DHIS2_QUERY,
  DELETE_DHIS2_QUERY
} from './action-types';
import initialState from './initial-state';
import { COMPONENT_STATUS, DEFAULT_UUID } from '../../constants';

export default (state = initialState, action) => {
  switch (action.type) {
    //  DATA ELEMENTS ACTIONS - START ---------------------------------------------
    case GET_DHIS2_DATA_ELEMENTS_REQUEST:
      return {
        ...state,
        dataElements: {
          status: COMPONENT_STATUS.LOADING,
          data: action.payload
        }
      };
    case GET_DHIS2_DATA_ELEMENTS_SUCCESS:
      return {
        ...state,
        dataElements: {
          status: COMPONENT_STATUS.LOADED,
          data: action.payload.dataElementGroups
        }
      };
    case GET_DHIS2_DATA_ELEMENTS_FAIL:
      return {
        ...state,
        dataElements: {
          status: COMPONENT_STATUS.ERROR,
          data: action.payload
        }
      };
    //  DATA ELEMENTS ACTIONS - END -----------------------------------------------

    //  ORG UNITS ACTIONS - START -------------------------------------------------
    case GET_DHIS2_ORG_UNITS_REQUEST:
      return {
        ...state,
        orgUnits: {
          status: COMPONENT_STATUS.LOADING,
          data: action.payload
        }
      };
    case GET_DHIS2_ORG_UNITS_SUCCESS:
      return {
        ...state,
        orgUnits: {
          status: COMPONENT_STATUS.LOADED,
          data: action.payload.organisationUnits
        }
      };
    case GET_DHIS2_ORG_UNITS_FAIL:
      return {
        ...state,
        orgUnits: {
          status: COMPONENT_STATUS.ERROR,
          data: action.payload
        }
      };
    //  ORG UNITS ACTIONS - END ---------------------------------------------------

    //  ANALYTICS ACTIONS - START
    case GET_DHIS2_ANALYTICS_REQUEST:
      return {
        ...state,
        analytics: {
          status: COMPONENT_STATUS.LOADING,
          data: action.payload
        }
      };
    case GET_DHIS2_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: {
          status: COMPONENT_STATUS.LOADED,
          data: action.payload.organisationUnits
        }
      };
    case GET_DHIS2_ANALYTICS_FAIL:
      return {
        ...state,
        analytics: {
          status: COMPONENT_STATUS.ERROR,
          data: action.payload
        }
      };
    //  ANALYTICS ACTIONS - END ---------------------------------------------------

    //  QUERIES ACTIONS - START
    case CREATE_DHIS2_QUERY:
      return {
        ...state,
        queries: {
          ...state.queries,
          [DEFAULT_UUID]: {
            uid: DEFAULT_UUID,
            info: {
              createdAt: Date.now(),
              name: DEFAULT_UUID
            },
            ...action.payload,
            periodFrom: action.payload.periodFrom,
            periodTo: action.payload.periodTo
          }
        }
      };
    case UPDATE_DHIS2_QUERY:
      return {
        ...state,
        queries: {
          ...state.queries,
          [action.payload.uid]: {
            ...state.queries[action.payload.uid],
            ...action.payload,
            periodFrom: action.payload.periodFrom,
            periodTo: action.payload.periodTo
          }
        }
      };
    case DELETE_DHIS2_QUERY:
      delete state.queries[action.payload.uid];
      return {
        ...state,
        queries: state.queries
      };
    //  QUERIES ACTIONS - END ---------------------------------------------------

    default:
      return state;
  }
};
