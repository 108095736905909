import { default as api } from 'axios';
import { apiFail, apiSuccess } from '../../state/api/actions';

const configureAPI = (baseURL, auth) => {
  api.defaults.auth = auth;
  api.defaults.baseURL = baseURL;
};

const apiMiddleware = ({ dispatch }) => next => action => {
  next(action);

  if (
    !action.type ||
    action.type === 'API_REQUEST' ||
    !action.type.endsWith('_REQUEST') ||
    !action.payload ||
    !action.payload.API_CONFIG ||
    !action.payload.API_CONFIG.path
  ) {
    return;
  }

  const {
    baseURL,
    auth,
    path,
    onSuccess = apiSuccess,
    onStart,
    onFail = apiFail,
    onDownloadProgress,
    params = {}
  } = action.payload.API_CONFIG;

  if (baseURL && auth) {
    configureAPI(baseURL, auth);
  }

  if (onStart) {
    dispatch(onStart(path));
  }

  if (Array.isArray(path)) {
    api
      .all(
        path.map(currentPath =>
          api.get(currentPath, {
            withCredentials: true,
            onDownloadProgress: (...args) =>
              Boolean(onDownloadProgress) && dispatch(onDownloadProgress(args)),
            params
          })
        )
      )
      .then(
        api.spread(
          (...args) =>
            Boolean(onDownloadProgress) && dispatch(onSuccess(...args))
        )
      )
      .catch((...args) => dispatch(onFail(...args)));
  } else {
    api
      .get(path, {
        withCredentials: true,
        onDownloadProgress: (...args) =>
          Boolean(onDownloadProgress) && dispatch(onDownloadProgress(...args)),
        params
      })
      .then(({ data }) => {
        dispatch(onSuccess(data));
      })
      .catch(({ response }) => {
        dispatch(onFail(response.data || response));
      });
  }
};

export default apiMiddleware;
