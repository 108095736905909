import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { renderRoutes } from 'react-router-config';
import configureStore, { history } from './state/configure-store';

import './i18n';
import * as serviceWorker from './serviceWorker';
import routes from './routes';
import './index.css';
import { init as initTableau } from './utils/tableau-wrapper';

initTableau();

render(
  <Suspense fallback={null}>
    <Provider store={configureStore()}>
      <ConnectedRouter history={history}>
        {renderRoutes(routes)}
      </ConnectedRouter>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
