import { localizationUtils } from '../../utils';
import Layout from './Layout';
import resources from './locales';
import {
  signInStart,
  signOut,
  handleUnauthorizedAccess
} from '../../state/profile/actions';
import { connect } from 'react-redux';

const { withLocalization } = localizationUtils;

export const mapStateToProps = (
  { profile, properties: { loader = {} } },
  { route: { rules: { isPrivate } = {} } = {} }
) => ({
  profile,
  showHeader: isPrivate && profile.logged,
  showLoader: loader.show,
  loader
});

export const mapDispatchToProps = dispatch => ({
  signInStart: data => {
    dispatch(signInStart(data));
  },
  signOut: data => {
    dispatch(signOut(data));
  },
  handleRouteAccess: ({ rules, isLogged }) => {
    if (rules.isPrivate && !isLogged) {
      dispatch(handleUnauthorizedAccess());
    }
  }
});

const ConnectedLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);

export default withLocalization(ConnectedLayout, resources, 'ConnectedLayout');
