import { css } from 'styled-components';

const styles = {
  container: () => css`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  `,
  arrowDropDown: css`
    color: white;
  `
};

export default styles;
