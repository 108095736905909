import { COMPONENT_STATUS, DEFAULT_UUID, PERIOD_TYPES } from '../../constants';

const initialState = {
  dataElements: {
    status: COMPONENT_STATUS.INITIAL,
    data: []
  },
  orgUnits: {
    status: COMPONENT_STATUS.INITIAL,
    data: []
  },
  analytics: {
    status: COMPONENT_STATUS.INITIAL,
    data: []
  },
  queries: {
    [DEFAULT_UUID]: {
      uid: DEFAULT_UUID,
      info: {
        createdAt: '1572011695380',
        name: DEFAULT_UUID,
        description: 'current query'
      },
      dataElementGroups: [
        {
          name: 'Acute Flaccid Paralysis (AFP) ',
          id: 'oDkJh5Ddh7d',
          dataElements: {
            name: 'Anaemia new',
            id: 'HLPuaFB7Frw'
          }
        }
      ],
      dataElements: [
        {
          name: 'Anaemia new',
          id: 'HLPuaFB7Frw'
        }
      ],
      organisationUnits: [
        {
          id: 'cDw53Ej8rju',
          displayName: 'Afro Arab Clinic'
        }
      ],
      periodFrom: Date.now(),
      periodTo: Date.now(),
      periodType: PERIOD_TYPES.LAST_YEAR
    }
  }
};

export default initialState;
