const tableau = window.tableau;

/**
 * Wrapper for the global tableau object
 */

const init = () => {
  const tableauConnector = tableau.makeConnector();

  tableauConnector.getSchema = function(schemaCallback) {
    const connectionData = JSON.parse(tableau.connectionData);
    window.tableau.log('getSchema - connectionData: ', tableau.connectionData); //debug

    schemaCallback([connectionData.schema]);
  };

  tableauConnector.getData = function(table, doneCallback) {
    const connectionData = JSON.parse(tableau.connectionData);
    window.tableau.log('getData - connectionData: ', tableau.connectionData); // debug
    const tableData = connectionData.data;
    table.appendRows(tableData);
    doneCallback();
  };

  tableau.registerConnector(tableauConnector);
};

/**
 * Send the payload to tableau
 *
 * @param name The connection name
 * @param payload The payload object (contains the 'schema' and table 'data')
 */
const submit = (name, payload) => {
  tableau.connectionName = name;
  tableau.connectionData = payload;
  tableau.submit();
};

/**
 * Tableau data types
 */
const dataTypes = tableau.dataTypeEnum;
const abortWithError = tableau.abortWithError;

export { abortWithError, dataTypes, init, submit };
