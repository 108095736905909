export const STATUS_OK = 200;
export const STATUS_UNAUTHORIZED = 401;
export const STATUS_TEXT_UNAUTHORIZED = 'UNAUTHORIZED';
export const STATUS_NOT_FOUND = 404;
export const STATUS_TEXT_NOT_FOUND = 'NOT_FOUND';

export const API_PATH_PROFILE = '/api/me';
export const API_PATH_DATA_ELEMENTS =
  '/api/dataElementGroups.json?fields=id,name,dataElements[id,name,domainType]&paging=false';
export const API_PATH_ORG_UNITS =
  '/api/organisationUnits.json?fields=id,name,level,parent&order=level%3Aasc&paging=false';
export const API_PATH_DATA_ELEMENT_GROUPS_DIRTY =
  'api/dataElements?level=1&paging=false&filter=domainType:ne:AGGREGATE&filter=dataElementGroups.id:!null&fields=id,dataElementGroups[id]';
export const API_PATH_ANALYTICS = '/api/analytics';
export const URL_PATH_ACCOUNT_RECOVERY =
  '/dhis-web-commons/security/recovery.action';
