import { THEMES } from '../../constants';

const initialState = {
  theme: { name: THEMES.BAO, brand: THEMES.BAO },
  api: {
    baseURL: null,
    username: null,
    password: null
  }
};

const mockedInitialState = {
  ...initialState,
  api: {
    baseURL: 'https://play.dhis2.org/dev',
    username: 'admin',
    password: 'district'
  }
};

export default process.env.REACT_APP_USE_MOCK !== 'true'
  ? initialState
  : mockedInitialState;
