import { Text, View } from '@baosystems/nebula';
import React from 'react';
import styled from 'styled-components';
import styles from './styles';
import PropTypes from 'prop-types';

const Title = styled(View)(styles.title);
const Component = styled(View)(styles.component);
const Description = styled(View)(styles.description);

const Loader = ({
  title,
  label,
  progress,
  description,
  isIndeterminate,
  align
}) => (
  <>
    {title && <Title>{title}</Title>}
    <Component
      progress={progress}
      isIndeterminate={isIndeterminate}
      align={align}
    >
      <Text>{label}</Text>
      {!isIndeterminate && <Text>{progress}%</Text>}
    </Component>
    {description && <Description align={align}>{description}</Description>}
  </>
);

Loader.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  isIndeterminate: PropTypes.bool,
  progress: PropTypes.number,
  description: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right', 'center'])
};

Loader.defaultProps = {
  isIndeterminate: true,
  progress: 0
};

export default Loader;
