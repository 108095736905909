import * as navigation from './navigation';
import { THEMES } from '@baosystems/nebula/dist/index';

const THEMES_LIST = [THEMES.BAO, THEMES.DHIS2];
const COMPONENT_STATUS_LIST = [
  'initial',
  'loading',
  'loaded',
  'error',
  'indeterminate'
];
const COMPONENT_STATUS = {
  INITIAL: 'initial',
  LOADING: 'loading',
  LOADED: 'loaded',
  IMPORTING: 'IMPORTING',
  IMPORTED: 'IMPORTED',
  STOPPING: 'STOPPING',
  STOPPED: 'STOPPED',
  INDETERMINATE: 'indeterminate',
  ERROR: 'error'
};
export const IMPORT_STATUS = {
  INITIAL: 'initial',
  IMPORTING: 'importing',
  IMPORTED: 'imported',
  ERROR: 'error'
};

export const PERIOD_TYPES = {
  THIS_YEAR: 'THIS_YEAR',
  LAST_YEAR: 'LAST_YEAR',
  LAST_12_MONTHS: 'LAST_12_MONTHS',
  LAST_SIX_MONTH: 'LAST_SIX_MONTH',
  THIS_QUARTER: 'THIS_QUARTER',
  LAST_QUARTER: 'LAST_QUARTER',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  CUSTOM: 'CUSTOM'
};

export const PERIOD_TYPES_LIST = Object.values(PERIOD_TYPES);

export const DEFAULT_UUID = 'current';

export {
  navigation,
  THEMES,
  THEMES_LIST,
  COMPONENT_STATUS,
  COMPONENT_STATUS_LIST
};
