import { css } from 'styled-components';

const styles = {
  container: () => css`
    display: flex;
    height: 60px;
    width: 100%;
    align-self: flex-end;
    border-top: 1px solid ${props => props.theme.tokens.COLOR_NEUTRAL_GREY_3};
    background-color: ${props => props.theme.tokens.COLOR_NEUTRAL_WHITE};
    align-items: center;
    padding: ${props => props.theme.tokens.SPACING_NORMAL_BASE}px
      ${props => props.theme.tokens.SPACING_NORMAL_MAX * 2}px;

    svg {
      fill: ${props => props.theme.tokens.COLOR_NEUTRAL_GREY_3};
    }
    span {
      padding-top: 4px;
    }
  `,
  creditsText: () => css`
    & {
      flex: 1;
      color: ${props => props.theme.tokens.COLOR_NEUTRAL_GREY_6};
      margin: 0 ${props => props.theme.tokens.SPACING_SMALL_MAX}px;
    }
  `,
  nav: () => css`
    margin-left: ${props => props.theme.tokens.SPACING_SMALL_MAX * 2}px;
    justify-self: flex-end;
    color: ${props => props.theme.tokens.COLOR_BASE_PRIMARY};

    a {
      margin-left: ${props => props.theme.tokens.SPACING_SMALL_MAX * 2}px;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: ${props => props.theme.tokens.COLOR_BASE_PRIMARY};

      &:hover {
        text-decoration: underline;
      }
    }
  `
};

export default styles;
