import React from 'react';
import { renderRoutes } from 'react-router-config';
import styled from 'styled-components/macro';
import { View } from '@baosystems/nebula';
import styles from './styles';

const Container = styled(View)(styles.container);

const App = ({ route }) => <Container>{renderRoutes(route.routes)}</Container>;

export default App;
