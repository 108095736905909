import {
  CREATE_DHIS2_QUERY,
  DELETE_DHIS2_QUERY,
  UPDATE_DHIS2_QUERY
} from '../action-types';

const createDHIS2Query = payload => ({
  type: CREATE_DHIS2_QUERY,
  payload
});

const updateDHIS2Query = payload => ({
  type: UPDATE_DHIS2_QUERY,
  payload
});

const deleteDHIS2Query = payload => ({
  type: DELETE_DHIS2_QUERY,
  payload
});

export { createDHIS2Query, updateDHIS2Query, deleteDHIS2Query };
