import {
  GET_DHIS2_ANALYTICS_FAIL,
  GET_DHIS2_ANALYTICS_REQUEST,
  GET_DHIS2_ANALYTICS_SUCCESS
} from '../action-types';
import { API_PATH_ANALYTICS } from '../../../constants/api';
import { queryUtils } from '../../../utils';
import { tableauImportStart } from '../../tableau/actions';

/**
 * Send a request to fetch DHIS 2 Analytics information
 *
 * @param payload The query model
 * @return {{payload: {query: *, API_CONFIG: {path: string, onFail: *, onSuccess: *}}, type: *}}
 */
const getDHIS2AnalyticsRequest = payload => ({
  type: GET_DHIS2_ANALYTICS_REQUEST,
  payload: {
    query: payload,
    API_CONFIG: {
      path: API_PATH_ANALYTICS + queryUtils.getDHIS2AnalyticsQuery(payload),
      onSuccess: getDHIS2AnalyticsSuccess,
      onFail: getDHIS2AnalyticsFail
    }
  }
});

const getDHIS2AnalyticsSuccess = payload => dispatch => {
  dispatch({
    type: GET_DHIS2_ANALYTICS_SUCCESS,
    payload
  });

  dispatch(tableauImportStart(payload));
};

const getDHIS2AnalyticsFail = payload => ({
  type: GET_DHIS2_ANALYTICS_FAIL,
  payload
});

export {
  getDHIS2AnalyticsRequest,
  getDHIS2AnalyticsSuccess,
  getDHIS2AnalyticsFail
};
