import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ThemeProvider } from 'styled-components';
import { useTheme } from '../../utils/hooks';
import { View } from '@baosystems/nebula';
import Header from '../Header';
import Footer from '../Footer';
import { ROUTES } from '../../constants/navigation';
import { renderRoutes } from 'react-router-config';
import { COMPONENT_STATUS_LIST, THEMES_LIST } from '../../constants';
import Loader from '../Loader/Loader';
import styles from './styles';

const Container = styled(View)(styles.container);
const Main = styled(View)(styles.contentContainer);
const LoaderContainer = styled(View)(styles.loaderContainer);

const Layout = ({
  route,
  t,
  theme,
  showHeader,
  showLoader,
  loader,
  profile,
  signOut,
  handleRouteAccess
}) => {
  // Check route access
  useEffect(
    () => handleRouteAccess({ rules: route.rules, isLogged: profile.logged }),
    [route.rules, profile, handleRouteAccess]
  );
  return (
    <ThemeProvider theme={useTheme(theme)}>
      <Container>
        {showHeader && (
          <Header
            profile={profile.data}
            headerNavigation={{
              signOut: {
                label: t('header.navigation.signOut'),
                to: ROUTES.ROOT.PATH
              }
            }}
            signOut={signOut}
          />
        )}
        <Main>
          {renderRoutes(route.routes)}
          {showLoader && (
            <LoaderContainer>
              <Loader {...loader} />
            </LoaderContainer>
          )}
        </Main>
        <Footer
          creditsText={t('footer.credits')}
          navigation={[
            {
              label: t('footer.navigation.guide'),
              to: 'https://www.interoperabilityhub.org/Tableau+Manual.pdf',
              target: 'blank'
            }
          ]}
        />
      </Container>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  showHeader: PropTypes.bool,
  theme: PropTypes.shape({
    name: PropTypes.oneOf(THEMES_LIST),
    brand: PropTypes.oneOf(THEMES_LIST)
  }),
  profile: PropTypes.shape({
    logged: PropTypes.bool,
    status: PropTypes.oneOf(COMPONENT_STATUS_LIST),
    data: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string
    })
  }),
  showLoader: PropTypes.bool,
  loader: PropTypes.shape({
    status: PropTypes.oneOf(COMPONENT_STATUS_LIST),
    title: PropTypes.string,
    label: PropTypes.string,
    progress: PropTypes.number
  }),
  signOut: PropTypes.func,
  handleRouteAccess: PropTypes.func,
  t: PropTypes.func,
  route: PropTypes.object,
  history: PropTypes.object
};

Layout.defaultProps = {
  showHeader: true,
  showLoader: false,
  loader: {
    title: 'Import data from DHIS2',
    label: "Loading from DHIS2. Please, wait until it's complete...",
    isIndeterminate: false,
    progress: 47
  }
};

export default Layout;
