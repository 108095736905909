import { css } from 'styled-components';

const styles = {
  container: () => css`
    display: flex;
    height: 60px;
    width: 100%;
    background-color: ${props => props.theme.tokens.COLOR_NEUTRAL_GREY_7};
    padding: ${props => props.theme.tokens.SPACING_NORMAL_BASE}px
      ${props => props.theme.tokens.SPACING_NORMAL_MAX * 2}px;
  `,
  logoContainer: () => css`
    flex: 1;
    min-width: 152px;
    margin-right: ${props => props.theme.tokens.SPACING_NORMAL_BASE}px;
    justify-self: flex-start;
    g > path {
      fill: #fff;
    }
  `,
  navigationContainer: () => css`
    justify-self: flex-end;
  `,
  profile: {
    email: () => css`
      font-size: 1.6rem;
      color: ${props => props.theme.tokens.COLOR_NEUTRAL_GREY_6};
    `
  },
  signOutLink: () => css`
    font-size: 1.6rem;
    color: ${props => props.theme.tokens.COLOR_NEUTRAL_WHITE};
    transition: color 100ms ease-in-out;
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.tokens.COLOR_BASE_PRIMARY};
      text-decoration: underline;
    }
    &:active {
      color: ${props => props.theme.tokens.COLOR_BASE_SECONDARY};
    }
  `,
  nav: () => css`
    margin-left: ${props => props.theme.tokens.SPACING_SMALL_MAX * 2}px;
    justify-self: flex-end;
  `
};

export default styles;
