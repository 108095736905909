import {
  GET_DHIS2_ORG_UNITS_FAIL,
  GET_DHIS2_ORG_UNITS_REQUEST,
  GET_DHIS2_ORG_UNITS_SUCCESS
} from '../action-types';
import { API_PATH_ORG_UNITS } from '../../../constants/api';

const getDHIS2OrgUnitsRequest = payload => ({
  type: GET_DHIS2_ORG_UNITS_REQUEST,
  payload: {
    ...payload,
    API_CONFIG: {
      path: API_PATH_ORG_UNITS,
      onSuccess: getDHIS2OrgUnitsSuccess,
      onFail: getDHIS2OrgUnitsFail
    }
  }
});

const getDHIS2OrgUnitsSuccess = payload => ({
  type: GET_DHIS2_ORG_UNITS_SUCCESS,
  payload
});

const getDHIS2OrgUnitsFail = payload => ({
  type: GET_DHIS2_ORG_UNITS_FAIL,
  payload
});

export {
  getDHIS2OrgUnitsRequest,
  getDHIS2OrgUnitsSuccess,
  getDHIS2OrgUnitsFail
};
