import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createBrowserHistory from 'history/createBrowserHistory';
import thunk from 'redux-thunk';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { createMiddleware as createGTMMiddleware } from 'redux-beacon';
import createRootReducer from './root-reducers';
import eventsMap from './event-definitions';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
import apiMiddleware from '../utils/middleware/api';

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_ROOT_ROUTER || '/#/'
});

Sentry.init({
  dsn: 'https://56c6fddcd0f3401f9a9e69c1373d8169@sentry.io/1802070',
  release: process.env.REACT_APP_VERSION,
  environment: process.env.ENVIRONMENT || 'production'
});

const gtmMiddleware = createGTMMiddleware(eventsMap, GoogleTagManager());
const middleWares = [
  thunk,
  routerMiddleware(history),
  gtmMiddleware,
  apiMiddleware,
  createSentryMiddleware(Sentry)
];

export default function configureStore(preloadedState) {
  return createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(applyMiddleware(...middleWares))
  );
}
