import { css } from 'styled-components';
const styles = {
  container: () => css`
    display: flex;
    flex: 1;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    background-color: ${props => props.theme.tokens.COLOR_NEUTRAL_GREY_1};
  `,
  contentContainer: () => css`
    align-self: center;
    width: 776px;
    flex: 1;
    padding: ${props => props.theme.tokens.SPACING_NORMAL_MIN}px 0;
    position: relative;
  `,
  loaderContainer: ({ theme: { tokens } }) => css`
    padding-top: ${tokens.SPACING_BIG_MIN}px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${tokens.COLOR_NEUTRAL_GREY_1};
    z-index: 100;
  `
};

export default styles;
